import { Injectable } from '@angular/core';
import { PathImagePipe } from 'src/app/shared/pipes/path-image.pipe';
import { environment } from 'src/environments/environment';
import { QUIZ_LINK } from '../constants/config';

declare const _klOnsite: any;

@Injectable({
  providedIn: 'root'
})
export class KlaviyoService {
  private pathImagePipe: any;

  constructor() {
    this.pathImagePipe = new PathImagePipe();
  }

  trackEvent(event: string, properties: any): void {
    _klOnsite.push(['track', event, properties]);
  }

  identifyUser(email: string, properties?: any): void {
    _klOnsite.push(['identify', { email, ...properties }]);
  }

  viewedProductEvent(product: any) {
    const price = this.getProductPrice(product);
    const productUrl = this.getProductUrl(product);
    const productImageUrl = this.getProductImageUrl(product);
    const productCategory = this.getProductCategoryTitle(product);
    const value = {
      ProductName: product.title,
      ProductID: product.id,
      SKU: product.sku,
      Categories: productCategory,
      ImageURL: productImageUrl,
      URL: productUrl,
      Brand: '',
      Price: price,
      CompareAtPrice: 0
    };
    _klOnsite.push(['track', 'Viewed Product', value]);
  }

  startedCheckoutEvent(product: any, order: any) {
    const eventId = new Date().getTime() / 1000;
    const productUrl = this.getProductUrl(product);
    const productImageUrl = this.getProductImageUrl(product);
    const categories = product.categories.map((item: any) => item.title);
    const items = order.products.map((item: any) => {
      return {
        ProductID: product?.id,
        SKU: product?.sku,
        ProductName: item?.name,
        Quantity: item?.quantity,
        ItemPrice: item?.price,
        RowTotal: item?.price,
        ProductURL: productUrl,
        ImageURL: productImageUrl,
        ProductCategories: categories
      };
    });
    const value = {
      $event_id: eventId,
      $value: order?.total,
      ItemNames: [product.title],
      CheckoutURL: `${environment.APP_ENDPOINT}${QUIZ_LINK}`,
      Categories: categories,
      Items: items
    };
    _klOnsite.push(['track', 'Started Checkout', value]);
  }

  private getProductCategoryTitle(product: any): string {
    if (product?.default_category) {
      return product.default_category?.title;
    } else if (product?.categories?.length) {
      return product.categories[0]?.title;
    } else {
      return '';
    }
  }

  private getProductPrice(product: any): number {
    if (product?.price?.length) {
      return product.price.find((item: any) => item.default)?.price;
    } else {
      return 0;
    }
  }

  private getProductUrl(product: any): string {
    return `${environment.APP_ENDPOINT}/product-detail/${
      product?.template === '2' ? 'variable' : 'simple'
    }/${product?.slug}`;
  }

  private getProductImageUrl(product: any): string {
    return this.pathImagePipe.transform(product.image, 'original', 'image');
  }
}
